import { Controller } from '@hotwired/stimulus'
import tippy from 'tippy.js';
import _ from 'lodash';
import { flashEffect } from 'helpers/effects'

export default class extends Controller {
  static targets = ['actions', 'text', 'textOverlay', 'textPlaceholder', 'field', 'input', 'index']

  connect() {
    this._renderHighlights()
  }

  disconnect() {
    this.destroy()
  }

  inputTargetConnected(element) {
    if (element.value) {
      element.readOnly = false
      element.dataset.previousValue = element.value
    }
  }

  indexTargetConnected(element) {
    const input = this._findInput(element.dataset.tag, element.closest(element.dataset.annotateWrapper))

    if (input && !element.value) {
      const match = this.textTarget.innerText.trim().match(this._highlightRegexp(input.value))

      if (match) { element.value = match.index }
    }
  }

  indexTargetDisconnected() {
    this._renderHighlights()
  }

  tooltip(event) {
    if (window.getSelection().isCollapsed) {
      return this.destroy()
    }

    if (!this.textOverlayTarget.contains(event.target)) { return }

    this.destroy()
    this.tooltipInstance = this._initializeTippy(event.target)
  }

  destroy() {
    if (!this.tooltipInstance) { return }

    this.tooltipInstance.destroy()
    this.tooltipInstance = null
  }

  update({ params }, callback = null) {
    if (!params.tag) { return }

    const selection = window.getSelection()
    const phrase = selection.toString()
    // depending on the selection direction, focus can be smaller than anchor
    const indexValue = Math.min(selection.anchorOffset, selection.focusOffset)

    if (phrase.trim() === '') { return }

    if (!this._matchesText(phrase) || this._overlapsIndex(indexValue, params.tag)) {
      flashEffect(this, {
        message: 'Selection invalid, please update',
        wrapperClass: 'alert-error',
        icons: ['fa-solid', 'fa-triangle-exclamation']
      })
      return
    }

    if (callback) { callback(this) }

    const input = this._findInput(params.tag)
    input.value = phrase
    input.readOnly = false
    input.dataset.previousValue = phrase

    const indexInput = this._findIndexInput(params.tag, input.closest(input.dataset.annotateWrapper))
    indexInput.value = indexValue

    this._renderHighlights()
  }

  // Update sentence from stimulus event
  // resetting existing annotations
  updateSentence({ detail: { content } }) {
    if (content) {
      this.textTarget.innerHTML = content
      this.textPlaceholderTarget.innerHTML = content
      this.textOverlayTarget.innerHTML = content
      this.fieldTarget.value = content
      this.inputTargets.forEach(el => el.value = '')
      this.indexTargets.forEach(el => el.value = '')
    }
  }

  add({ params }) {
    this.update({ params }, (ctrl) => {
      ctrl.element.querySelector(`[data-button-tag="${params.tag}"]`)?.click()
    })
  }

  updateText({ target }) {
    if (!target.dataset.previousValue) { return }

    const previousValue = target.dataset.previousValue

    if (target.value.trim()) {
      this._removeHighlights()

      const indexInput = this._findIndexInput(target.dataset.tag, target.closest(target.dataset.annotateWrapper))
      const begin = Number(indexInput.value)
      const end = begin + previousValue.length
      this.textTarget.innerHTML = this._replaceAt(this.textContent, target.value,
                                                  begin, end)

      // increment indexes for highlights after the current is updated
      this.indexTargets.forEach((el) => {
        // ignore index before the current
        if (Number(el.value) <= begin) { return }

        const newIndex = Number(el.value) + (target.value.length - previousValue.length)
        el.value = (newIndex < 0) ? 0 : newIndex
      })

      this.textOverlayTarget.innerHTML = this.textTarget.innerHTML
      this.textPlaceholderTarget.innerHTML = this.textTarget.innerHTML
      target.dataset.previousValue = target.value

      this.fieldTarget.value = this.textTarget.innerText

      this._renderHighlights()
    } else {
      target.readOnly = true
    }
  }

  _initializeTippy(element) {
    return tippy(element, {
      content: this.actionsTarget.innerHTML,
      theme: 'light',
      allowHTML: true,
      interactive: true,
      zIndex: 999999,
      showOnCreate: true,
      placement: 'top',
      trigger: 'manual',
      maxWidth: 'none',
      onClickOutside(instance,) {
        if (instance.state.isVisible) {
          instance.hide()
        }
        if (!instance.state.isDestroyed) {
          instance.destroy()
        }
      }
    })
  }

  _highlightFragment(phrase, tag, wrapper) {
    if (phrase.trim() === '') { return }

    let content = null
    const phraseMark = `<mark class="tag-${tag}">${phrase}</mark>`

    const indexInput = this._findIndexInput(tag, wrapper)

    if (indexInput.value) {
      const begin = Number(indexInput.value)
      const end = Number(indexInput.value) + phrase.length

      content = this._replaceAt(this.textContent, phraseMark, begin, end)
    } else {
      content = this.textContent.replace(
        this._highlightRegexp(phrase),
        phraseMark
      )
    }

    this.textTarget.innerHTML = content
  }

  _replaceAt(text, replacement, indexBegin, indexEnd) {
    return text.slice(0, indexBegin) + replacement + text.slice(indexEnd)
  }

  _matchesText(phrase) {
    return this.textContent.match(this._highlightRegexp(phrase))
  }

  _overlapsIndex(index, tag) {
    return this.indexRanges.some(([begin, end, indexTag]) => {
      if (indexTag === tag) { return false }

      // handle beginning of sentence annotate
      if (index === 0 && begin === 0 && end === 0) { return false }

      return index >= begin && index <= end
    })

  }

  _highlightRegexp(phrase) {
    return new RegExp(_.escapeRegExp(phrase), 'i')
  }

  _renderHighlights() {
    this._removeHighlights()

    // filter out duplicate indexes
    this.indexTargets.filter((el, i, self) => i === self.findIndex((t) => ( t.value === el.value)))
      // filter out index overlaps on any field
      .filter((el) => {
        const index = Number(el.value)
        const matchingRanges = this.indexRanges.filter(([begin, end,]) => index >= begin && index <= end)

        return matchingRanges.length <= 1
      })
      // render highlights in reverse order,
      .toSorted((a, b) => Number(b.value) - Number(a.value)).forEach((el) => {
        const input = this._findInput(el.dataset.tag, el.closest(el.dataset.annotateWrapper))
        if (input) { this._highlightFragment(input.value, el.dataset.tag, input.closest(input.dataset.annotateWrapper)) }
      })

    this.textPlaceholderTarget.innerHTML = this.cleanedTextContent
    this.textOverlayTarget.innerHTML = this.cleanedTextContent
    this.fieldTarget.value = this.cleanedTextContent
  }

  _removeHighlights() {
    this.textTarget.innerHTML = this._removeHighlightsFrom(this.textContent)
  }

  _removeHighlightsFrom(text) {
    this.textTarget.querySelectorAll('mark').forEach((mark) => {
       text = text.replaceAll(_.unescape(mark.outerHTML), mark.textContent)
    })

    return text
  }

  _findInput(tag, wrapper) {
    return this.inputTargets.findLast((el) => {
      return el.dataset.tag === tag && (wrapper ? wrapper.contains(el) : true)
    })
  }

  _findIndexInput(tag, wrapper) {
    return this.indexTargets.findLast((el) => {
      return el.dataset.tag === tag && (wrapper ? wrapper.contains(el) : true)
    })
  }

  get indexRanges() {
    return this.indexTargets.map((el) => {
      const input = this._findInput(el.dataset.tag, el.closest(el.dataset.annotateWrapper))
      if (!input) { return [] }

      const begin = Number(el.value)
      const end = begin + input.value.length

      return [begin, end, el.dataset.tag]
    })
  }

  get cleanedTextContent () {
    const text = this._removeHighlightsFrom(this.textContent)

    return _.unescape(text)
  }

  get textContent () {
    return _.unescape(this.textTarget.innerHTML.trim())
  }
}
