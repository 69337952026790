import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['input', 'display', 'edit', 'source'];

  connect() {
    this.show();
  }

  show() {
    this.displayTargets.forEach(el => el.classList.remove('hidden'))

    this.editTargets.forEach(el => el.classList.add('hidden'))
    this.inputTarget.classList.add('hidden')
    this.inputTarget.disabled = true
  }

  edit() {
    this.displayTargets.forEach(el => el.classList.add('hidden'))

    if (this.hasSourceTarget) {
      this.inputTarget.value = this.sourceTarget.value
    }

    this.editTargets.forEach(el => el.classList.remove('hidden'))
    this.inputTarget.classList.remove('hidden')
    this.inputTarget.disabled = false
    this.inputTarget.focus();
  }

  save() {
    this.dispatch('save', { detail: { content: this.inputTarget.value } })

    this.show();
  }
}
