import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['icon', 'input', 'button']
  static classes = ['offIcon', 'offWrapper']
  static values = { offLabel: String, wrapperLookup: String }

  connect() {
    this.onLabel = this.buttonTarget.getAttribute('aria-label')
    this._reload()
  }

  toggle() {
    if (this.inputTarget.value === 'true') {
      this.inputTarget.value = 'false'
    } else {
      this.inputTarget.value = 'true'
    }

    this.inputTarget.dispatchEvent(new Event('change'))

    this._reload()
  }

  _reload() {
    if (this.inputTarget.value === 'true') {
      this.wrapperElement.classList.remove(this.offWrapperClass)
      this.iconTarget.classList.remove(this.offIconClass)
      this.buttonTarget.setAttribute('aria-label', this.onLabel)
    } else {
      this.wrapperElement.classList.add(this.offWrapperClass)
      this.iconTarget.classList.add(this.offIconClass)
      this.buttonTarget.setAttribute('aria-label', this.offLabelValue)
    }
  }

  get wrapperElement() {
    if (this.hasWrapperLookupValue) {
      return this.element.closest(this.wrapperLookupValue)
    } else {
      return this.element
    }
  }
}
